import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import vmMachineApi from '@/api/gpuHub/vmMachines';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import SkeletonBoxWithoutLoading from '@/components/SkeletonBoxWithoutLoading';
import { colorGpuNodeColor, vmMachineStatusText, vmMachineStatus, vmMachineStatusColor }
    from "@/constant/vmMachineStatusConst";
import moment from 'moment';
import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const { io } = require("socket.io-client");
import { socketIO } from "@/constant/config";
import mappingTicketType from "@/constant/mappingTicketType"; 

const defaultOrderby = "pkg.PRICING";
const bootingShutdownOrderby = "vm.LOADING_TIME";
const creatingOrderby = "vm.CREATED_AT";
const runingOrderby = "pkg.PRICING";
const regrexPercentUpload = /^(\[\d+, \w+]) - Upload image: ([\w.]+)%$/;
const regrexPercentDownload = /^(\[\d+, \w+]) - Download image: ([\w.]+)%$/;
const regrexPercentClone = /^(\[\d+, \w+]) - clone image: ([\w.]+)%$/;
const regrexPercentSnapshot = /^(\[\d+, \w+]) - create snapshot: ([\w.]+)%$/;
const systemTarget = process.env.VUE_APP_SYSTEM;
const isMinimumBootEnabled = process.env.VUE_APP_MINIMUM_BOOT_ENABLED !== undefined && process.env.VUE_APP_MINIMUM_BOOT_ENABLED === "true";
export default {
    extends: baseComponent,
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 6.5rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    watch: {
        ownedTarget(newVal) {
            if (this.ownedTarget !== null) this.getAllResult(1);
        },
        $route(newVal) {
            if(this.$route.query && this.$route.query.status){
                console.log('this.$route', this.$route.query.status);
                switch(this.$route.query.status){
                    case "running":
                        this.runningFilter();
                        break;
                    case "shuttingdown":
                        this.shuttingdownFilter();
                        break;
                    case "booting":
                        this.bootingFilter();
                        break;
                    default:
                        this.getAllResult(1);
                        break;
                }
            }
        }
    },
    components: {
        detailMachine: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/detailVmMachine"),
            loading: SkeletonBox,
        }),
        bootingAvgStatistics: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/avgBootingDurationByDate"),
            loading: SkeletonBox,
        }),
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        }),
        renderSoftwareFocus: lazyLoadComponent({
            componentFactory: () => import("@/views/users/RenderSoftwareFocus"),
            loading: SkeletonBoxWithoutLoading,
        }),
        reportSuportTicketModalContent: lazyLoadComponent({
            componentFactory: () => import("@/views/modal/RentalService/ReportSupportTicket"),
            loading: SkeletonBox,
        }),
    },
    data() {
        return {
            isLoading: true,
            VUE_APP_API_HOST: process.env.VUE_APP_API_HOST,
            debounceFnc: null,
            debounceFilterFnc: null,
            timeForCreatingImage: 3000,
            intervalUpdate: null,
            intervalUpdateGeneralData: null,
            colorGpuNodeColor: colorGpuNodeColor,
            vmMachineStatus: vmMachineStatus,
            vmMachineStatusColor: vmMachineStatusColor,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {
                    isTransactionsWithRevenue: false,
                },
                filterIsAlive: "",
                filterbySoftware: "",
                machineOnline: "",
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: defaultOrderby,
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            toogleFilter: false,
            elementDetail: null,
            serverStatus: [],
            countImageOverState: null,
            summaryInfo: {
                availableGPU: 0,
                availableCPU: 0,
                canBoot: false,
                canBootCpu: false,
                totalGPU: 0,
                totalCPU: 0,
                runningGPU: 0,
                runningCPU: 0,
            },
            capicityConst: {
                C30Gb: 32212254720,
                C50Gb: 53687091200
            },
            socket: null,
            socketIo4: null,
            throt_fun: null,
            reportTicketModal: {
                isShowModal: false,
                machineInfo: null,
                postModelData: null
            }
        };
    },
    mounted() {
        console.log(`Start connect to ${socketIO.urlServerSocketIO4}`);
        this.socket = io(socketIO.urlServerSocketIO4, { forceNew: true });
        this.socket.on("connect", (socket) => {
            console.log(`Connected to ${socketIO.urlServerSocketIO4}`);
        });
        this.socket.on(socketIO.eventServerState, (data) => {
            if (data.event === "message") { 
                let hasMachineIdProp =  data.data.hasOwnProperty('MachineId')
                var findElement = null;
                if (!hasMachineIdProp) findElement = this.elements.data.find(x => x.vmMachineInfo.macAddress === data.data.MacAddress);
                else findElement = this.elements.data.find(x => x.vmMachineId === data.data.MachineId);
                if (findElement && findElement !== null) {
                    findElement.message = data.data.Message;
                    if (regrexPercentUpload.test(data.data.Message)) {
                        var regrexPercentUploadMatch = data.data.Message.match(regrexPercentUpload);
                        findElement.vmMachineInfo.loadingProgress = regrexPercentUploadMatch[2];
                    }

                    if (regrexPercentDownload.test(data.data.Message)) {
                        var regrexPercentDownloadMatch = data.data.Message.match(regrexPercentDownload);
                        findElement.vmMachineInfo.loadingProgress = regrexPercentDownloadMatch[2];
                    }

                    if (regrexPercentClone.test(data.data.Message)) {
                        var regrexPercentCloneMatch = data.data.Message.match(regrexPercentClone);
                        findElement.vmMachineInfo.loadingProgress = regrexPercentCloneMatch[2];
                    }

                    if (regrexPercentSnapshot.test(data.data.Message)) {
                        var regrexPercentSnapshotMatch = data.data.Message.match(regrexPercentSnapshot);
                        findElement.vmMachineInfo.loadingProgress = regrexPercentSnapshotMatch[2];
                    }
                }
            }
        });
        this.socket.on("close", this.tryReconnect);
        this.socket.connect();


        this.socketIo4 = io(socketIO.urlServerSocketIO4, { forceNew: true, reconnectionDelayMax: 10000, });
        this.socketIo4.on("connect", (socketIo4Instance) => {
            console.log(`Connected to ${socketIO.urlServerSocketIO4}`);
        });
        this.socketIo4.on(socketIO.machineReadyIssuesEvent, (data) => {
            var foundMachineItem = null;
            foundMachineItem = this.elements.data.find(x => x.vmMachineId === data.data.MachineId);
            if (foundMachineItem && foundMachineItem !== null) {
                foundMachineItem.machineReadyIssues = data.data;
                if (data.data.MachineName !== data.data.MachineNameCurrent
                    || !data.data.IsRemoteReady
                    || !data.data.IsValidGPU
                    || !data.data.IsGPUReady
                    || !data.data.IsMapFileExists
                    || (data.data.IsDomainMember !== null && !data.data.IsDomainMember)) {
                    foundMachineItem.isReadyIssues = true;
                }
                else {
                    foundMachineItem.isReadyIssues = false;
                }
            }
        });
        this.socketIo4.on("irender.machine.issues.admin", (data) => {
            let issuesData = data.data;
            switch (issuesData.Topic) {
                case 'Image.ComputerName.Duplicate':
                    var foundMachineItem = this.elements.data.find(
                        x => x.vmMachineInfo.computerName === issuesData.HostName
                            && x.vmMachineInfo.macAddress === issuesData.MacAddress
                    );
                    if (foundMachineItem && foundMachineItem !== null) {
                        foundMachineItem.message = null;
                        foundMachineItem.isComputerNameDuplicate = true;
                        foundMachineItem.numberOfComputerNameDuplicate = issuesData.Data.MachineCount;
                    }
                    break;
            }
        });
    },
    beforeDestroy() {
        this.socket.disconnect();
        this.socketIo4.disconnect();
        clearInterval(this.intervalUpdate);
        clearInterval(this.intervalUpdateGeneralData);
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user
        }),
        isMinCostFirstHour(){
            switch(systemTarget){
                case "CHIP":
                    return true;
                case "IRENDER":
                default:
                    return false;
            }
        },
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: defaultOrderby,
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                orderBy: this.elements.pagingItem.orderBy,
                directionSort: this.elements.pagingItem.directionSort,
                filterItem: (this.elements.filterItem.statusFilterArray && this.elements.filterItem.statusFilterArray !== null)
                    ? Object.assign(this.elements.filterItem, {
                        statusFilter: this.elements.filterItem.statusFilterArray.join(","),
                        machineOnline: this.elements.machineOnline,
                        filterIsAlive: this.elements.filterIsAlive,
                        filterbySoftware: this.elements.filterbySoftware
                    })
                    : Object.assign(this.elements.filterItem, {
                        machineOnline: this.elements.machineOnline,
                        filterIsAlive: this.elements.filterIsAlive,
                        filterbySoftware: this.elements.filterbySoftware
                    })
            };
        },
        machineStatusFilter() {
            return Object.keys(vmMachineStatusText).map(x => {
                return { id: x, text: vmMachineStatusText[x] };
            });
        },
        machinePackageFilter() {
            return this.rentalPackage.map(x => {
                return { id: x.id, text: x.name };
            });
        },
        cpuNodesAvailability() {
            if (this.summaryInfo.totalCPU > 0) {
                var availableCPU = (this.summaryInfo.availableCPU / this.summaryInfo.totalCPU) * 100;
                if (availableCPU < 30) return { status: "Low", classes: 'danger', percent: availableCPU, striped: false };
                if (availableCPU < 70) return { status: "Medium", classes: 'info', percent: availableCPU, striped: false };
                else return { status: "High", classes: 'success', percent: availableCPU, striped: false };
            }
            return { status: "Cheking..", classes: 'muted', percent: 0, striped: true, };
        },
        gpuNodesAvailability() {
            if (this.summaryInfo.totalGPU > 0) {
                var availableGPU = (this.summaryInfo.availableGPU / this.summaryInfo.totalGPU) * 100;
                if (availableGPU < 30) return { status: "Low", classes: 'danger', percent: availableGPU, striped: false };
                if (availableGPU < 70) return { status: "Medium", classes: 'info', percent: availableGPU, striped: false };
                else return { status: "High", classes: 'success', percent: availableGPU, striped: false, };
            }
            return {
                status: "Cheking..",
                classes: 'muted',
                percent: 0,
                striped: true,
            };
        },
        isB2cView() {
            return this.$route.name === 'B2bGPUHubMachines';
        }
    },
    created() {
        this.loadRentalPackage();
        //this.loadRentalServerStatus();
        this.getCountImageOverState();
        if(this.$route.query && this.$route.query.status){
            console.log('this.$route', this.$route.query.status);
            switch(this.$route.query.status){
                case "running":
                    this.runningFilter();
                    break;
                case "shuttingdown":
                    this.shuttingdownFilter();
                    break;
                case "booting":
                    this.bootingFilter();
                    break;
                default:
                    this.getAllResult(1);
                    break;
            }
        }
        else{
            this.getAllResult(1);
        }
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
        this.intervalUpdateGeneralData = setInterval(() => {
            //this.loadRentalServerStatus();
            this.getCountImageOverState();
            //this.pageClickHandle(this.elements.pagingItem.pageIndex);
        }, 10000);
    },
    methods: {
        tryReconnect() {
            setTimeout(() => {
                this.socket.io.open((err) => {
                    if (err) {
                        this.tryReconnect();
                    }
                });
            }, 2000);
        },
        loadRentalPackage() {
            vmMachineApi.getPackageList().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.rentalPackage = response.data.data.data;
                    //this.hideLoading();
                    this.calculateInterval();
                }
                else {
                    this.rentalPackage = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rentalPackage = [];
            });
        },
        loadRentalServerStatus() {
            vmMachineApi.getAllServerReport().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.serverStatus = response.data.data.data.map(item => {
                        return {
                            "package": item.package,
                            "total": item.total,
                            "available": item.available,
                            "status_text": item.statusText
                        };
                    });
                    //this.hideLoading();
                }
                else {
                    this.serverStatus = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.serverStatus = [];
            });
        },
        getCountImageOverState() {
            let funcTarget = this.$route.name === 'GPUHubMachinesFpt' ? "getCountImageOverStateAI" : "getCountImageOverState";
            vmMachineApi[funcTarget]().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.countImageOverState = response.data.data;
                }
                else {
                    //this.countImageOverState = null;
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                //this.countImageOverState = null;
            });
        },
        sortChanged(data) {
            this.$set(this.elements.pagingItem, 'orderBy', data.orderBy);
            this.$set(this.elements.pagingItem, 'directionSort', data.directionSort);
            this.pageClickHandle(1);
        },
        getElementsList(pageNumber) {
            this.showLoading();
            this.requestParam.isB2bList = (this.$route.name === "B2bGPUHubMachines");
            vmMachineApi.getElementsList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.$nextTick(() => { this.hideLoading(); });
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.$nextTick(() => { this.hideLoading(); });
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.$nextTick(() => { this.hideLoading(); });
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        filterPackage(item) {
            this.elements.filterItem.statusFilterArray = [vmMachineStatus.Running, vmMachineStatus.Starting, vmMachineStatus.Restaring, vmMachineStatus.Off];
            this.elements.filterItem.isShuttingDown = true;
            this.elements.filterItem.packageName = this.$store.state.gpuNodeName[item.package].id;
            this.onFilterInput(false);
        },
        miningCoinFilter() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.getCountImageOverState();
            this.elements.machineOnline = '';
            this.elements.filterItem = {};
            this.elements.filterItem.isMiningCoin = true;
            this.onFilterInput(false);
        },
        creatingFilter() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.getCountImageOverState();
            this.elements.pagingItem.orderBy = creatingOrderby;
            this.elements.pagingItem.directionSort = "desc";
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.elements.machineOnline = '';
            this.elements.filterItem = {};
            this.elements.filterItem.statusFilterArray = [vmMachineStatus.Creating];
            this.onFilterInput(false);
        },
        shuttingdownFilter() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.getCountImageOverState();
            this.elements.pagingItem.orderBy = bootingShutdownOrderby;
            this.elements.pagingItem.directionSort = "asc";
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.elements.machineOnline = '';
            this.elements.filterItem = {};
            this.elements.filterItem.statusFilterArray = [vmMachineStatus.Off];
            this.elements.filterItem.isShuttingDown = true;
            this.onFilterInput(false);
        },
        bootingFilter() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.getCountImageOverState();
            this.elements.pagingItem.orderBy = bootingShutdownOrderby;
            this.elements.pagingItem.directionSort = "asc";
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.elements.machineOnline = '';
            this.elements.filterItem = {};
            this.elements.filterItem.statusFilterArray = [vmMachineStatus.Starting];
            this.onFilterInput(false);
        },
        runningFilter() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.getCountImageOverState();
            this.elements.pagingItem.orderBy = runingOrderby;
            this.elements.pagingItem.directionSort = "desc";
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.elements.machineOnline = '';
            this.elements.filterItem = {};
            this.elements.filterItem.statusFilterArray = [vmMachineStatus.Running];
            this.onFilterInput(false);
        },
        resetingFilter() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.getCountImageOverState();
            this.elements.pagingItem.orderBy = runingOrderby;
            this.elements.pagingItem.directionSort = "desc";
            this.elements.filterIsAlive = "";
            this.elements.filterbySoftware = "";
            this.elements.machineOnline = '';
            this.elements.filterItem = {};
            this.elements.filterItem.statusFilterArray = [vmMachineStatus.Reset];
            this.onFilterInput(false);
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            console.log("elementDetail", this.elementDetail);
            this.$nextTick(() => {
                $("#detailMachineModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailMachineModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
            console.log("elementDetail", this.elementDetail);
        },
        // Calculate interval
        calculateInterval() {
            this.intervalUpdate = setInterval(() => {
                this.elements.data.forEach(element => {
                    if (element.vmMachineInfo.status === this.vmMachineStatus.Running) {
                        element.vmMachineInfo.uptime = parseInt(element.vmMachineInfo.uptime);
                        element.vmMachineInfo.rentalServicePack.pricing = parseFloat(element.vmMachineInfo.rentalServicePack.pricing);
                        let minimumBoot = element.vmMachineInfo.rentalServicePack.minimumBoot;
                        let uptimeCalc = 0;
                        if(isMinimumBootEnabled && minimumBoot > 0){
                            uptimeCalc =  (element.vmMachineInfo.uptime > 3599 ? element.vmMachineInfo.uptime : 3599);
                        }
                        else uptimeCalc = element.vmMachineInfo.uptime;
                        uptimeCalc += 1;
                        element.vmMachineInfo.uptimeIfMinimumBoot = uptimeCalc;
                        element.vmMachineInfo.uptime += 1;
                        //let uptimeCalcInHour = moment.duration(uptimeCalc, 'seconds').asHours();
                        //element.estimatedTotal = Math.round((uptimeCalcInHour * element.vmMachineInfo.rentalServicePack.pricing + Number.EPSILON) * 10000) / 10000;
                        var places = 4;
                        element.estimatedTotal = Math.round(((uptimeCalc * element.vmMachineInfo.rentalServicePack.pricing) / (60 * 60) + Number.EPSILON) * 10000) / 10000;
                        this.$set(element, "estimatedTotal", Math.round(((uptimeCalc * element.vmMachineInfo.rentalServicePack.pricing) / (60 * 60) + Number.EPSILON) * 10000) / 10000);
                    }
                });
            }, 1000);
        },
        proccessingEventMachine(message) {
            switch (message.cmdName) {
                case "UpdateServerStatus":
                    //console.log("UpdateServerStatus", message.data);
                    if (message.data !== null) this.$set(this, "serverStatus", message.data);
                    break;
                case "UpdateSummaryInfo":
                    //console.log("UpdateSummaryInfo", message.data);
                    if (message.data !== null) this.$set(this, "summaryInfo", message.data);
                    break;
                case "UpdateRdpStatus":
                    //console.log("UpdateRdpStatus", message.data.data);
                    var messageUpdateRdpStatus = message.data.data;
                    if (messageUpdateRdpStatus !== null) {
                        var itemTargetRdpUpdated = this.elements.data.find(element => element.id === messageUpdateRdpStatus.vmMachineId);
                        if (itemTargetRdpUpdated !== null) {
                            this.$set(itemTargetRdpUpdated, "canRemoteRdpReady", messageUpdateRdpStatus.remoteReady);
                        }
                    }
                    break;
            }
        },
        getColorOfImageCapicity(value) {
            if (value > this.capicityConst.C30Gb && value <= this.capicityConst.C50Gb) {
                return "warning";
            }
            if (value > this.capicityConst.C50Gb) {
                return "danger";
            }
            return "info";
        },
        openBootStatistics() {
            $("#bootDurationStatistics").modal("show");
        },
        bootingDuration(loadingTime, startTime) {
            try {
                if (!loadingTime || !startTime) return "-- -- -- --";
                var totalSecondsVal = moment.utc(startTime).diff(moment.utc(loadingTime), 'seconds', true)
                var totalDurations = moment.duration(totalSecondsVal, 'seconds');
                var durationFormat = `${(totalDurations._data.days > 0 ? 'D[d]' : '')}`;
                durationFormat += `${(totalDurations._data.hours > 0 ? 'H[h]' : '')}`;
                durationFormat += `${(totalDurations._data.minutes > 0 ? 'm[m]' : '')}`;
                return totalDurations.format(durationFormat, {
                    trim: false
                });
            } catch (err) {
                return "";
            }
        },
        miningCoinTrusted(machineItem) {
            commonAct.showConfirm(`<div class="text-left">Trusted image <strong>[${machineItem.vmMachineId}] ${machineItem.vmMachineInfo.name}</strong> ?</div>`, () => {
                this.showLoading();
                vmMachineApi.trustedImage(machineItem.vmMachineId).then(response => {
                    if (response.data.result === 0) {
                        this.showSuccessToast(`<div class="text-left">Trusted image <strong>[${machineItem.vmMachineId}] ${machineItem.vmMachineInfo.name}</strong> has been updated</div>`);
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
        onWaypoint({ element }) {
            // going: in, out
            // direction: top, right, bottom, left
            try {
                if (!(element.isGettingIssues)) {
                    element.isGettingIssues = true;
                    vmMachineApi.getMachineIssues(element.vmMachineId)
                        .then(response => {
                            if (response.data.result === 0) {
                                try {
                                    if (response.data.data != null) {
                                        element.machineReadyIssues = JSON.parse(response.data.data.machineState);
                                        if (element.machineReadyIssues.MachineName !== element.machineReadyIssues.MachineNameCurrent
                                            || !element.machineReadyIssues.IsRemoteReady
                                            || !element.machineReadyIssues.IsValidGPU
                                            || !element.machineReadyIssues.IsGPUReady
                                            || !element.machineReadyIssues.IsMapFileExists
                                            || (element.machineReadyIssues.IsDomainMember !== null && !element.machineReadyIssues.IsDomainMember)) {
                                            element.isReadyIssues = true;
                                        }
                                        else {
                                            element.isReadyIssues = false;
                                        }
                                    }
                                    else element.isReadyIssues = false;

                                } catch (error) { console.error(error); }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }
            } catch (err) { console.error(err); }
            if (!element.hasOwnProperty("isLazyloadData") || !element.isLazyloadData) {
                getlazyLoadInfo(this, element.userInfo, element.userId);
                this.$set(element, 'isLazyloadData', true);
                console.log("onWaypoint");
            }
        },

        openSupportTicketModal(targetItem) {
            let defaultCategory = mappingTicketType[`MACHINE_${targetItem.vmMachineInfo.status}`] || mappingTicketType["MACHINE"];
            this.$set(this.reportTicketModal, "machineInfo", targetItem);
            this.$set(this.reportTicketModal, "postModelData", {
                subject: `[${targetItem.vmMachineId}] ${targetItem.userInfo.username}`,
                mess: "",
                category_id: defaultCategory,
                type_id: null,
                machineId: `${targetItem.vmMachineId}`,
                username: `${targetItem.userInfo.username}`,
                name: this.userInfo.fullName
            }); 
            this.$nextTick(() => {
                this.$set(this.reportTicketModal, "isShowModal", true);
            });
        },
        closeSupportTicketModal() {
            this.$set(this.reportTicketModal, "isShowModal", false);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", null); 
        },
        getClassBind(element) {
            return {
                'low-balance': element.balance < 10,
                'blink-row-tr': (element.softwareFocus && element.softwareFocus.isHighlight),
                'partnerUser': element.userInfo && element.userInfo.partnerInfo && element.userInfo.partnerInfo !== null
            };
        }
    }
}